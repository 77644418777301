import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import moment from "moment"
import { useSelector } from "react-redux"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const EventProcessForm03 = props => {
  const [user, setUser] = useState(undefined)
  const [location, setLocation] = useState(undefined)

  const { usersByRole, locations } = useSelector(state => ({
    usersByRole: state.UsersList.usersByRole,
    locations: state.LocationsList.locations,
  }))

  useEffect(() => {
    if (props.event !== undefined) {
      const user = usersByRole.find(
        user => user.userId === parseInt(props.event.responsibleUserId)
      )
      setUser(user)

      const location = locations.find(
        location => location.locationId === parseInt(props.event.locationId)
      )
      setLocation(location)
    }
  }, [props.event])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      return
    }

    console.log("Values", values)

    if (!values.eventStatus) {
      values.eventStatus = props.event?.status ?? 1
    }

    props.onComplete(values)
  }

  return (
    <Fragment>
      <div className="container">
        <AvForm className="form-horizontal" onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <h3>Información del evento</h3>
              <p>
                <strong>Nombre:</strong> {props.event.name}
              </p>
              <p>
                <strong>Responsable:</strong>{" "}
                {user !== undefined ? `${user.firstName} ${user.lastName}` : ""}
              </p>
              <p>
                <strong>Tipo de evento:</strong> {props.event.eventType}
              </p>
              {props.event.eventType === "Presencial" && (
                <p>
                  <strong>Sede:</strong>{" "}
                  {location !== undefined
                    ? `${location.name} (${location.municipality}, ${location.state}`
                    : props.event.locationName}
                </p>
              )}
              <p>
                <strong>Fecha y hora de inicio del evento:</strong>{" "}
                {moment(props.event.startDate).format("DD/MM/YYYY HH:mm")}
              </p>
              <p>
                <strong>Fecha y hora de fin del evento:</strong>{" "}
                {moment(props.event.endDate).format("DD/MM/YYYY HH:mm")}
              </p>
              {props.event.date1 !== undefined && (
                <p>
                  <strong>Fecha 1:</strong>{" "}
                  {moment(props.event.date1).format("DD/MM/YYYY HH:mm")}
                </p>
              )}
              {props.event.date2 !== undefined && (
                <p>
                  <strong>Fecha 2:</strong>{" "}
                  {moment(props.event.date2).format("DD/MM/YYYY HH:mm")}
                </p>
              )}
            </Col>
            <Col md={6}>
              <AvField
                name="eventStatus"
                value={props.event?.status ?? 1}
                label="* Publicar:"
                className="form-control"
                placeholder=""
                type="select"
                validate={{}}
              >
                <option key={1} value={1}>
                  Publicar
                </option>
                <option key={0} value={0}>
                  No publicar
                </option>
              </AvField>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6}>
              <Carousel showArrows={true}>
                {props.event.imagePaths.map((image, index) => (
                  <div key={index}>
                    <img src={image} alt="Evento" className="img-responsive" />
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col md={6}>
              {props.event.eventType === "Webinar" && (
                <h4>
                  <a target="_blank" href={props.event.link} rel="noreferrer">
                    <i className="fa fa-link"></i> Sitio web del evento
                  </a>
                </h4>
              )}
              <h4>Breve descripción</h4>
              <div
                dangerouslySetInnerHTML={{ __html: props.event.description }}
              ></div>
            </Col>
          </Row>
          <Row>
            <div className="col-12 d-flex justify-content-end">
              <button onClick={props.onBack} className="btn btn-light">
                <i className="fa fa-arrow-left"></i> Atrás
              </button>
              <button type="submit" className="btn btn-primary">
                Finalizar <i className="fa fa-check"></i>
              </button>
            </div>
          </Row>
        </AvForm>
      </div>
    </Fragment>
  )
}

EventProcessForm03.propTypes = {
  onComplete: PropTypes.func,
  onBack: PropTypes.func,
  event: PropTypes.object,
}

export default EventProcessForm03
