import React, { Component, Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb2"
import EventProcessBox from "components/Interfester/EventProcessBox"
import EventProcessForm01 from "components/Interfester/EventProcessForm01"
import EventProcessForm02 from "components/Interfester/EventProcessForm02"
import EventProcessForm03 from "components/Interfester/EventProcessForm03"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  createEvent,
  createEventClean,
  getUsers,
  getUsersByRole,
  listLocations,
} from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"

const AgregarEvento = props => {
  const [active, setActive] = useState(1)
  const [event, setEvent] = useState(undefined)
  const [alert, setAlert] = useState(false)

  const dispatch = useDispatch()

  const { usersByRole, locations, loading, done, error, whoIAm } = useSelector(
    state => ({
      whoIAm: state.Profile.user,
      usersByRole: state.UsersList.usersByRole,
      locations: state.LocationsList.locations,
      loading: state.EventCreate.loading,
      done: state.EventCreate.done,
      error: state.EventCreate.error,
    })
  )

  const user =
    whoIAm === undefined
      ? {
          idUsuario: 0,
          nombre: "",
          apellido: "",
          idRol: 0,
        }
      : whoIAm

  useEffect(() => {
    dispatch(getUsersByRole(2))
    dispatch(listLocations())
  }, [])

  const saveStep1 = data => {
    setEvent({
      ...event,
      name: data.eventName,
      locationName: data.eventType === "Presencial" ? data.locationName : "",
      eventType: data.eventType,
      responsibleUserId: data.userId,
      locationId: 0,
      startDate: data.startDate,
      endDate: data.endDate,
      date1: event.eventType === "Webinar" ? data.date1 : null,
      date2: event.eventType === "Webinar" ? data.date2 : null,
    })
    setActive(2)
  }

  const saveStep2 = data => {
    setEvent({
      ...event,
      imagePaths: data.imagePaths,
      link: event.eventType === "Webinar" ? data.link : "",
      description: data.description,
    })
    setActive(3)
  }

  const goBack = () => {
    setActive(active - 1)
  }

  const handleSaveEvent = values => {
    console.log("Event", event)
    const newEvent = {
      ...event,
      status: values.eventStatus,
      responsibleUserId: parseInt(event.responsibleUserId),
      locationId: parseInt(event.locationId),
      url: event.link,
      image: event.imagePaths,
      userId: user.idUsuario,
    }
    setEvent(newEvent)
    dispatch(createEvent(newEvent))
  }

  if (done) {
    dispatch(createEventClean())
    setAlert(true)
  }

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Agregar evento | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Agregar evento</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "home", title: "La Academia Fester", link: "/start" },
                  {
                    key: "eventos",
                    title: "Gestión de eventos",
                    link: "/start/adm-eventos",
                  },
                  { key: "eventos-add", title: "Agregar evento" },
                ]}
              />
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8 offset-md-2">
                        <EventProcessBox
                          legend="Proceso para nuevo evento"
                          active={active}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  {active === 1 && (
                    <EventProcessForm01
                      event={event}
                      onComplete={saveStep1}
                      users={usersByRole}
                      locations={locations}
                    />
                  )}
                  {active === 2 && (
                    <EventProcessForm02
                      event={event}
                      onComplete={saveStep2}
                      onBack={goBack}
                    />
                  )}
                  {active === 3 && (
                    <EventProcessForm03
                      event={event}
                      onComplete={handleSaveEvent}
                      onBack={goBack}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {alert && (
        <SweetAlert
          title="Evento registrado exitosamente"
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            props.history.push("/start/adm-eventos")
          }}
        >
          <p>El evento ha sido registrado exitosamente.</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

AgregarEvento.propTypes = {
  history: PropTypes.object,
}

export default withRouter(AgregarEvento)
